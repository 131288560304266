import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    font-family: Montserrat, sans-serif;
  }

  html, body {
    height: 100%;
    background: ${({ theme }) => theme.palette.background.main};
  }

  #root {
    display: flex;
    min-height: 100%; 
  }

  * {
    box-sizing: border-box;

    /* Customize website's scrollbar */
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    /* For Firefox */
    scrollbar-color: darkgrey;
    scrollbar-width: thin;
}
`;
