import React from 'react';
import { styled } from '@mui/material';
import { ReactNode } from 'react';

const TableActionsWrapper = styled('div')`
  width: min-content;
  display: flex;
  justify-content: end;

  * {
    margin: 0 !important;
  }

  button {
    flex: none;
  }
`;

export const TableActions = ({ children }: { children: ReactNode }) => {
  return <TableActionsWrapper>{children}</TableActionsWrapper>;
};
