import React from 'react';
import Icon from '../../bit/components/icon';
import copyIcon from '../../images/copy.svg';
import Input from '../Input';
import { copyToClipboard } from '../../resources/functions';
import { Props } from './interface';
import styled from 'styled-components';
import Notification from '../Notification';
import { useTranslation } from 'react-i18next';

const StyledInput = styled(Input)`
  input,
  textarea {
    resize: none;
    padding-right: 46px;
    word-break: break-all;
  }
`;

const InputCopy = ({
  fullWidth,
  label,
  multiline,
  text,
  ...otherProps
}: Props) => {
  const { t } = useTranslation();

  const handleClick = React.useCallback(() => {
    copyToClipboard(text);
    Notification.show({ message: t('common.copied') });
  }, [t, text]);

  return (
    <StyledInput
      {...otherProps}
      readOnly
      fullWidth={fullWidth}
      value={text}
      label={label}
      multiline={multiline}
      endIcon={<Icon src={copyIcon} onClick={handleClick} />}
    />
  );
};

export default InputCopy;
