import React from 'react';

const useHeaderPopper = (text: string, timer?: number) => {
  const [isShow, setIsShow] = React.useState(true);

  React.useEffect(() => {
    if (timer === undefined) return;

    let timeout: NodeJS.Timeout | null = null;

    timeout = setTimeout(() => {
      setIsShow(false);
    }, timer);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timer]);

  return {
    title: text,
    isShow,
    toggleShowState: setIsShow
  };
};

export default useHeaderPopper;
