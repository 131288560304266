import React from 'react';
import ReactPaginate from 'react-paginate';
import { PaginationProps } from './interface';
import theme from '../theme/theme';
import { limitPageOptions } from '../../../resources/constants';
import { PaginationContainer, PageLimit } from './PaginationStyled';
import { useTranslation } from 'react-i18next';

PaginationContainer.defaultProps = { theme };

const Pagination = ({ page, pages, onChange, limitProps }: PaginationProps) => {
  const { t } = useTranslation();

  const handleChange = React.useCallback(
    ({ selected }: { selected: number }) => onChange(selected + 1),
    [onChange]
  );
  const hasPages = pages > 1;

  return (
    <PaginationContainer
      $hasLimit={Boolean(limitProps?.onChange)}
      $hasPages={hasPages}
    >
      {hasPages && (
        <ReactPaginate
          onPageChange={handleChange}
          disableInitialCallback
          pageCount={pages}
          forcePage={page - 1}
          pageRangeDisplayed={4}
          marginPagesDisplayed={1}
          nextLabel={t('common.next')}
          previousLabel={t('common.back')}
        />
      )}

      {hasPages && limitProps?.onChange && (
        <PageLimit
          value={limitProps.value}
          options={limitPageOptions}
          onChange={limitProps.onChange}
        />
      )}
    </PaginationContainer>
  );
};

export default Pagination;
