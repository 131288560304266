import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import LanguageSelect from '../../components/LanguageSelect';

import { AuthState, Languages } from '../../types';
import {
  ApiUsersConfirmPost,
  ApiUsersLoginPost,
  ApiUsersRegisterPost
} from '../../types/api/users';

import UserApi from '../../api/user';
import { useDispatch } from 'react-redux';
import { setTokenAsync } from '../../redux/slices/userSlice';

import Page from '../../components/Page';
import LogIn, { ErrorsState, LogInConfig } from '../../bit/components/log-in';

import Animation from '../../components/Animation';
import { InputValidateState } from '../../bit/components/input-base';
import Typography from '../../components/Typography';
import { color } from '../../bit/components/utils/utils';
import Logo from '../../components/Logo';
import { useHistory, useLocation } from 'react-router-dom';
import Checkbox from '../../bit/components/checkbox';
import ResendCode from '../../components/ResendCode';
import { StringParam, useQueryParam } from 'use-query-params';
import { facebookEvent, gtagEvent } from '../../resources/functions';
import useWithTelegram, { RegisterMode } from '../../hooks/useWithTelegram';
import Notification from '../../components/Notification';
import trimObjectStrings from '../../utils/trimObjectStrings';

const LogInForm = styled(LogIn)`
  padding: 24px;
  background: ${color('background.dark2')};
  box-shadow: 0 6px 80px rgba(0, 0, 0, 0.07);
  border-radius: 4px;

  position: relative;
  z-index: 1000;

  margin-top: 40px;

  border: 1px solid #373737;
  border-top: none;

  & > * > span {
    color: white;
  }

  label {
    color: ${color('text.first')} !important;
    font-size: 14px;
  }

  input,
  button {
    border-radius: 0;
  }

  button {
    border-radius: 4px;
  }

  input {
    color: white;
    background: transparent;
    border: 1px solid #efefef;
    border-radius: 4px;

    :focus {
      background: transparent;
    }

    ::placeholder {
      /* TEXT 2 */
      color: #989fa6;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
  padding: 0 16px;
  width: 100%;
  max-width: 408px;
`;

const LogInFooter = styled.div`
  margin-top: 16px;
`;

const LogInBlock = styled.div`
  & + & {
    margin-top: 16px;
  }
`;

const LogInBackground = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const TitleContainer = styled.div`
  white-space: pre-line;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: normal;
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  span {
    margin-left: 16px;

    a {
      text-decoration: none;
      color: ${color('primary.main')};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const messengerMinLength = 3;
const defaultMessengerProps: Pick<
  LogInConfig['inputs'][number],
  'inputProps'
> = { inputProps: { minLength: messengerMinLength } };

const checkIsFilledMessenger = (value: string) =>
  value && value.length >= messengerMinLength;

const LogInPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [referralLink] = useQueryParam('ref', StringParam);

  const [state, setState] = useState<AuthState>(AuthState.auth);

  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const { registerMode } = useWithTelegram();

  useEffect(() => {
    const routes = Object.values(AuthState);
    const currentRoute = location.pathname.slice(1) as AuthState;
    if (routes.includes(currentRoute)) {
      setState(currentRoute);
    } else {
      setState(AuthState.auth);
    }
    setPolicyConfirm(false);

    if (currentRoute === AuthState.register) {
      facebookEvent('track', 'Contact');
    }
  }, [location.pathname]);

  const [codeId, setCodeId] = useState<number | null>(null);
  const [errorsState, setErrorsState] = useState<ErrorsState>({});
  const [isDisabledButton, setDisabledButton] = useState(false);
  const [policyConfirm, setPolicyConfirm] = useState(false);
  const [
    registrationData,
    setRegistrationData
  ] = useState<ApiUsersRegisterPost>();

  useEffect(() => {
    setErrorsState({});
  }, [state]);

  const toCreateAccount = useCallback(() => {
    setState(AuthState.register);
    history.push(`/${AuthState.register}`);
  }, [history]);

  const toForgotPassword = useCallback(() => {
    setState(AuthState.forgot);
    history.push(`/${AuthState.forgot}`);
  }, [history]);

  const toCodeConfirm = useCallback(() => {
    setState(AuthState.code);
    history.push(`/${AuthState.code}`);
  }, [history]);

  const toLogIn = useCallback(() => {
    setState(AuthState.auth);
    history.push(`/${AuthState.auth}`);
  }, [history]);

  const toConfirmReset = useCallback(() => {
    setState(AuthState.confirmReset);
    history.push(`/${AuthState.confirmReset}`);
  }, [history]);

  const handleCheckPolicyConfirm = useCallback(() => {
    setPolicyConfirm((prevState) => {
      return !prevState;
    });
  }, []);

  const renderFooter = useCallback(() => {
    switch (state) {
      case AuthState.auth:
        return (
          <LogInFooter>
            <LogInBlock>
              <Typography
                isLinkStyles
                color={'accent' as any}
                variant='subtitle1'
                size={14}
                onClick={toForgotPassword}
              >
                {t('login.forgotPassword')}
              </Typography>
            </LogInBlock>
            <LogInBlock>
              <Typography color='first' variant='subtitle1' size={14}>
                {t('login.newUser')}
              </Typography>{' '}
              <Typography
                isLinkStyles
                onClick={toCreateAccount}
                color={'accent' as any}
                variant='subtitle1'
                size={14}
              >
                {t('login.createAccount')}
              </Typography>
            </LogInBlock>
          </LogInFooter>
        );
      case AuthState.code:
        return (
          <LogInFooter>
            <ResendCode data={registrationData} />
          </LogInFooter>
        );
      case AuthState.forgot:
        return (
          <LogInFooter>
            <LogInBlock>
              <Typography color='first' variant='subtitle1' size={14}>
                {t('forgot.knowAccount')}
              </Typography>{' '}
              <Typography
                isLinkStyles
                onClick={toLogIn}
                color={'accent' as any}
                variant='subtitle1'
                size={14}
              >
                {t('forgot.join')}
              </Typography>
            </LogInBlock>
          </LogInFooter>
        );
      case AuthState.register:
        return (
          <LogInFooter>
            <LogInBlock>
              <Typography color='first' variant='subtitle1' size={14}>
                {t('register.haveAccount')}
              </Typography>{' '}
              <Typography
                isLinkStyles
                onClick={toLogIn}
                color={'accent' as any}
                variant='subtitle1'
                size={14}
              >
                {t('register.join')}
              </Typography>
            </LogInBlock>
          </LogInFooter>
        );
      default:
        return null;
    }
  }, [state, toForgotPassword, t, toCreateAccount, registrationData, toLogIn]);

  const title = useMemo((): string => {
    switch (state) {
      default:
      case AuthState.auth:
        return t('login.title');
      case AuthState.forgot:
        return t('forgot.title');
      case AuthState.confirmReset:
      case AuthState.code:
        return t('code.title');
      case AuthState.register:
        return t('register.title');
    }
  }, [t, state]);

  const renderTitle = useCallback(() => {
    return (
      <TitleContainer>
        <div>{title}</div>
        <LanguageSelect isShort />
      </TitleContainer>
    );
  }, [title]);

  const registrationMessengers = useMemo(() => {
    const onlyTelegram = [
      {
        name: 'telegram',
        _key: 'register-telegram',
        label: t('fields.telegram.label'),
        placeholder: '@username',
        ...defaultMessengerProps
      }
    ];

    const withoutTelegram = [
      {
        name: 'whatsapp',
        _key: 'register-whatsapp',
        label: t('fields.whatsapp.label'),
        placeholder: '@username',
        ...defaultMessengerProps
      },
      {
        name: 'skype',
        _key: 'register-skype',
        label: t('fields.skype.label'),
        placeholder: '@username',
        ...defaultMessengerProps
      },
      {
        name: 'wechat',
        _key: 'register-wechat',
        label: t('fields.wechat.label'),
        placeholder: '@username',
        ...defaultMessengerProps
      }
    ];

    switch (registerMode) {
      case RegisterMode.onlyTelegram:
        return onlyTelegram;
      case RegisterMode.all:
        return [...onlyTelegram, ...withoutTelegram];
      default:
        return [];
    }
  }, [registerMode, t]);

  const inputs = useMemo((): LogInConfig['inputs'] => {
    switch (state) {
      default:
      case AuthState.auth:
        return [
          {
            _key: 'auth-email',
            name: 'email',
            label: t('fields.email.label'),
            placeholder: t('fields.email.placeholder')
          },
          {
            _key: 'auth-password',
            name: 'password',
            type: 'password',
            label: t('fields.password.label'),
            placeholder: t('fields.password.placeholder')
          }
        ];
      case AuthState.forgot:
        return [
          {
            _key: 'forgot-email',
            name: 'email',
            label: t('fields.email.label'),
            placeholder: t('fields.email.placeholder')
          }
        ];
      case AuthState.confirmReset:
        return [
          {
            _key: 'reset-code',
            name: 'code',
            autoComplete: 'new-password',
            label: t('fields.code.label'),
            placeholder: t('fields.code.placeholder')
          },
          {
            _key: 'new-password',
            name: 'password',
            type: 'password',
            autoComplete: 'new-password',
            label: t('fields.password.label'),
            placeholder: t('fields.password.placeholder')
          }
        ];
      case AuthState.code:
        return [
          {
            _key: 'code',
            name: 'code',
            label: t('fields.code.label'),
            placeholder: t('fields.code.placeholder')
          }
        ];
      case AuthState.register: {
        const defaultInputs: LogInConfig['inputs'] = [
          {
            name: 'email',
            _key: 'register-email',
            label: t('fields.email.label'),
            placeholder: t('fields.email.placeholder')
          },
          {
            name: 'password',
            type: 'password',
            _key: 'register-password',
            label: t('fields.password.label'),
            placeholder: t('fields.password.placeholder')
          }
        ];

        if (registerMode !== RegisterMode.onlyTelegram) {
          defaultInputs.push({
            additionalText: t('register.oneSocial'),
            _key: 'hint',
            name: ''
          });
        }

        return [...defaultInputs, ...registrationMessengers];
      }
    }
  }, [state, t, registerMode, registrationMessengers]);

  const buttons = useMemo<LogInConfig['buttons']>(() => {
    let children = t('login.button');

    switch (state) {
      default:
      case AuthState.auth:
        children = t('login.button');
        break;
      case AuthState.forgot:
      case AuthState.confirmReset:
        children = t('forgot.button');
        break;
      case AuthState.code:
        children = t('code.button');
        break;
      case AuthState.register:
        children = t('register.button');
        break;
    }

    const items: LogInConfig['buttons'] = [
      {
        children,
        size: 'middle',
        disabled: isDisabledButton
      }
    ];

    const rulesHref =
      language === Languages.ru
        ? '/rules.pdf?version=1'
        : '/rules-en.pdf?version=1';

    const policyHref =
      language === Languages.ru ? '/policy.pdf' : '/policy-en.pdf';

    if (state === AuthState.register) {
      items.unshift(
        <CheckboxContainer key='policy'>
          <Checkbox
            onChange={handleCheckPolicyConfirm}
            checked={policyConfirm}
          />
          <Typography color='first' variant='subtitle1' size={14}>
            {t('register.policy.p1')}{' '}
            <a target='_blank' href={rulesHref}>
              {t('register.policy.p2')}
            </a>{' '}
            {t('register.policy.p3')}{' '}
            <a target='_blank' href={policyHref}>
              {t('register.policy.p4')}
            </a>
          </Typography>
        </CheckboxContainer>
      );
    }

    return items;
  }, [
    t,
    language,
    state,
    handleCheckPolicyConfirm,
    policyConfirm,
    isDisabledButton
  ]);

  const config = useMemo<LogInConfig>(() => {
    return {
      title,
      inputs,
      buttons,
      renderFooter,
      renderTitle
    };
  }, [title, inputs, buttons, renderFooter, renderTitle]);

  const onSubmitForm = useCallback(
    async (data) => {
      const preparedData = trimObjectStrings(data);

      const email = (preparedData.email || '').trim().toLowerCase();

      if (state === AuthState.auth) {
        const body: ApiUsersLoginPost = {
          email,
          password: preparedData.password
        };

        try {
          setDisabledButton(true);
          const response = await UserApi.login(body);
          const token = response.data.data.token;
          dispatch(setTokenAsync(token));
        } catch (error) {
          const status = error.response?.status || 500;

          if (status === 400) {
            if (!body.password) {
              setErrorsState({
                password: InputValidateState.error
              });

              Notification.show({
                type: 'error',
                message: t('login.passwordError')
              });
            } else {
              setErrorsState({
                email: InputValidateState.error
              });

              Notification.show({
                type: 'error',
                message: t('login.emailError')
              });
            }
          } else if (status === 401) {
            setErrorsState({
              email: InputValidateState.error,
              password: InputValidateState.error
            });

            Notification.show({
              type: 'error',
              message: t('login.commonError')
            });
          }
        } finally {
          setDisabledButton(false);
        }
      }

      if (state === AuthState.code) {
        if (typeof codeId === 'number') {
          const body: ApiUsersConfirmPost = {
            codeId: codeId,
            code: preparedData.code
          };

          try {
            setDisabledButton(true);
            const response = await UserApi.confirm(body);
            const token = response.data.data.token;
            dispatch(setTokenAsync(token));
            history.push('/');
            facebookEvent('track', 'CompleteRegistration');
            gtagEvent('conversion', {
              send_to: 'AW-637045012/vXU6CPP0g74CEJSS4q8C'
            });
          } catch (error) {
            setErrorsState({
              code: InputValidateState.error
            });

            Notification.show({
              type: 'error',
              message: t('code.codeError')
            });
          } finally {
            setDisabledButton(false);
          }
        } else {
          console.log('error codeId');
        }
      }

      if (state === AuthState.register) {
        const isFilledTelegram = checkIsFilledMessenger(preparedData.telegram);
        const isFilledOtherMessengers = !!(
          checkIsFilledMessenger(preparedData.whatsapp) ||
          checkIsFilledMessenger(preparedData.skype) ||
          checkIsFilledMessenger(preparedData.wechat)
        );

        const isFilledAllMessengers =
          isFilledTelegram || isFilledOtherMessengers;

        const isFilledMessengers =
          registerMode === RegisterMode.onlyTelegram
            ? isFilledTelegram
            : isFilledAllMessengers;

        if (!email || !preparedData.password || !isFilledMessengers) {
          const errors: Record<string, InputValidateState> = {};

          if (!email) errors.email = InputValidateState.error;
          if (!preparedData.password)
            errors.password = InputValidateState.error;

          if (!isFilledMessengers) {
            switch (registerMode) {
              case RegisterMode.onlyTelegram:
                if (!checkIsFilledMessenger(preparedData.telegram))
                  errors.telegram = InputValidateState.error;
                break;
              case RegisterMode.all:
                if (!checkIsFilledMessenger(preparedData.skype))
                  errors.skype = InputValidateState.error;
                if (!checkIsFilledMessenger(preparedData.wechat))
                  errors.wechat = InputValidateState.error;
                if (!checkIsFilledMessenger(preparedData.telegram))
                  errors.telegram = InputValidateState.error;
                if (!checkIsFilledMessenger(preparedData.whatsapp))
                  errors.whatsapp = InputValidateState.error;
                break;
            }
          }

          setErrorsState(errors);

          Notification.show({
            type: 'error',
            message: t('register.commonError')
          });

          return;
        }

        if (!policyConfirm) {
          Notification.show({
            type: 'warning',
            message: t('register.policyConfirm')
          });

          return;
        }

        const body: ApiUsersRegisterPost = {
          email,
          password: preparedData.password,
          messengers: {
            telegram: preparedData.telegram,
            whatsapp: preparedData.whatsapp,
            skype: preparedData.skype,
            wechat: preparedData.wechat
          },
          repeatPassword: preparedData.password,
          ref: referralLink as string,
          lang: language
        };

        try {
          setDisabledButton(true);
          const response = await UserApi.register(body);
          setCodeId(response.data.data.codeId);
          toCodeConfirm();
          setRegistrationData(body);
        } catch (error) {
          const status = error.response?.status || 500;

          if (status === 409) {
            setErrorsState({ email: InputValidateState.error });
            Notification.show({
              type: 'error',
              message: t('register.emailError')
            });
          } else if (status === 400) {
            Notification.show({
              type: 'error',
              message: t('register.commonError')
            });
          }
        } finally {
          setDisabledButton(false);
        }
      }

      if (state === AuthState.forgot) {
        try {
          setDisabledButton(true);
          const response = await UserApi.resetPassword({ email });
          setCodeId(response.data.data.codeId);
          toConfirmReset();
        } catch (error) {
          setErrorsState({ email: InputValidateState.error });
          Notification.show({ message: t('forgot.emailError'), type: 'error' });
        } finally {
          setDisabledButton(false);
        }
      }

      if (state === AuthState.confirmReset && codeId) {
        try {
          setDisabledButton(true);
          await UserApi.confirmReset({
            code: preparedData.code,
            codeId,
            password: preparedData.password
          });
          toLogIn();
          Notification.show({
            type: 'success',
            message: t('forgot.confirmResetSuccess')
          });
        } catch (error) {
          const status = error.response?.status || 500;

          if (status === 401) {
            setErrorsState({ code: InputValidateState.error });
            Notification.show({ message: t('code.codeError'), type: 'error' });
          }
        } finally {
          setDisabledButton(false);
        }
      }
    },
    [
      state,
      codeId,
      dispatch,
      t,
      registerMode,
      policyConfirm,
      referralLink,
      language,
      toCodeConfirm,
      toConfirmReset,
      toLogIn
    ]
  );

  return (
    <Page
      isFullWidth
      isDarkBackground
      isHorizontalCenter
      withoutBackgroundPattern
    >
      <FormContainer>
        <Logo isLogIn />
        <LogInForm
          config={config}
          onSubmit={onSubmitForm}
          forceErrorsState={errorsState}
        />
      </FormContainer>
      <LogInBackground>
        <Animation opacity={0.6} />
      </LogInBackground>
    </Page>
  );
};

export default LogInPage;
