import theme from '../theme/theme';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';
import { ModalProps, StyledModalProps } from './interface';
import { transition } from '../utils/utils';
import ModalCloseIcon from '../modal-close-icon';

export const ModalContainer = styled.div<StyledModalProps>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  opacity: ${({ state }) => {
    switch (state) {
      case 'entered':
        return 1;
      case 'entering':
      case 'exiting':
      case 'exited':
        return 0;
    }
  }};
  ${transition({ name: 'opacity' })};
  z-index: ${(props) => props.theme.components.modal.zIndex};
`;

ModalContainer.defaultProps = {
  theme
};

export const ModalBackdrop = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`;

export const ModalScrollWrapper = styled.div`
  text-align: center;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  &:after {
    content: '';
    width: 0;
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }
`;

export const ModalContent = styled.div<{ maxWidth?: number }>`
  position: relative;
  text-align: left;
  display: inline-block;
  width: calc(100% - 64px);
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
  padding: 28px 32px;
  margin: 32px;
  vertical-align: middle;
  background-color: ${(props) => props.theme.components.modal.background};
  border-radius: ${(props) => props.theme.borderRadius};

  @media ${(props) => props.theme.media.tablet} {
    width: calc(100% - 30px);
    margin: 15px;
  }
`;

ModalContent.defaultProps = {
  theme
};

const StyledModalCloseIcon = styled(ModalCloseIcon)`
  position: absolute;
  top: 16px;
  right: 16px;
  color: ${(props) => props.theme.components.modal.closeIconColor};
`;

StyledModalCloseIcon.defaultProps = {
  theme
};

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const Modal = (props: ModalProps) => {
  const { open, onClose, children, maxWidth = 600, className } = props;

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return ReactDOM.createPortal(
    <Transition
      in={open}
      timeout={{
        enter: 0,
        exit: 250
      }}
      mountOnEnter
      unmountOnExit
    >
      {(state) => (
        <ModalContainer state={state} className={className}>
          <ModalBackdrop onClick={onClose} />
          <ModalScrollWrapper>
            <ModalContent maxWidth={maxWidth}>
              <StyledModalCloseIcon onClose={onClose} />
              <ModalBody>{children}</ModalBody>
            </ModalContent>
          </ModalScrollWrapper>
        </ModalContainer>
      )}
    </Transition>,
    document.body
  );
};

export default Modal;
