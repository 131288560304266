import 'reset-css';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import { store } from './redux/store';
import { Provider } from 'react-redux';

import { initI18n } from './lib/localization';

import App from './App';
import ThemeProvider from './components/ThemeProvider';
import Metrics from './components/Metrics';
import Notification from './components/Notification';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import GlobalStyles from './globalStyles';

initI18n();

const isProd = process.env.NODE_ENV === 'production';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        {isProd && <Metrics />}
        <ThemeProvider>
          <GlobalStyles />
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <QueryParamProvider ReactRouterRoute={Route}>
              <App />
              <Notification />
            </QueryParamProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
