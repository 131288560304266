import { TFunction } from 'i18next';
import { Currency, CurrencyKeys, Languages } from '../types';
import { DEFAULT_LANGUAGE, STORE_LANGUAGE } from './constants';

export const getLocalStorage = <T>(key: string): T | undefined => {
  try {
    const storage = localStorage.getItem(key);
    if (storage) {
      return JSON.parse(storage);
    }
  } catch (_) {
    return undefined;
  }
};

export const setLocalStorage = (
  key: string,
  value: string | Record<string, any>
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getUserPosition = (t: TFunction, type?: string) => {
  switch (type) {
    case 'admin':
      return t('common.admin');
    case 'manager':
      return t('common.manager');
    default:
      return type;
  }
};

export const copyToClipboard = (text?: string) => {
  if (!text) return;
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  textArea.remove();
};

export const truncate = (str: string, maxlength: number) => {
  if (str.length > maxlength) {
    return `${str.slice(0, maxlength - 3)}...`;
  }

  return str;
};

export const getDate = (date: Date, format = 'YYYY-MM-DD') => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  let result = format;
  result = result.replace('DD', String(day < 10 ? `0${day}` : day));
  result = result.replace('MM', String(month < 10 ? `0${month}` : month));
  result = result.replace('YYYY', String(year));
  result = result.replace('HH', String(hours));
  result = result.replace('mm', String(minutes));

  return result;
};

export const orNull = (value: number) => {
  if (value === Infinity) return 0;
  return value || 0;
};

export const getCurrencySymbol = (currency?: CurrencyKeys) => {
  if (currency) {
    return Currency[currency];
  }
  return currency || '';
};

export const facebookEvent = (target: string, event: string) => {
  const w = window as any;
  const interval = setInterval(() => {
    if (w.fbq) {
      w.fbq(target, event);
      clearInterval(interval);
    }
  }, 1000);
};

export const gtagEvent = (event: string, params: Record<string, unknown>) => {
  const w = window as any;
  if (w.gtag) w.gtag('event', event, params);
};

export const getDayStart = (date: Date = new Date()): Date => {
  return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const getDayEnd = (date: Date = new Date()): Date => {
  return new Date(new Date(date).setHours(23, 59, 59, 0));
};

export const getLanguage = (fallbackLng?: Languages): Languages | undefined => {
  const language =
    getLocalStorage<Languages>(STORE_LANGUAGE) || DEFAULT_LANGUAGE;

  if (Object.values(Languages).includes(language)) {
    return language;
  }

  if (fallbackLng) {
    return fallbackLng;
  }
};
