import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import Table from '../../components/Table';

import Button from '../../bit/components/button';
import { TableOption, TableRow } from '../../bit/components/table/interface';
import { useTranslation } from 'react-i18next';
import { AppCardActionTypes } from './interface';
import { useSelector } from 'react-redux';
import { selectApps } from '../../redux/slices/appsSlice';
import { App } from '../../types/apps';

interface AppsTableProps {
  isLoading: boolean;
  setApp: Dispatch<SetStateAction<App | undefined>>;
  setOpenAppModalState: Dispatch<SetStateAction<boolean>>;
  setIsNamingGeneratorModalOpen: Dispatch<SetStateAction<boolean>>;
}

const AppsTable = ({
  isLoading,
  setApp,
  setOpenAppModalState,
  setIsNamingGeneratorModalOpen
}: AppsTableProps) => {
  const { t } = useTranslation();

  const apps = useSelector(selectApps);

  const handleAppCardAction = useCallback(
    (type: AppCardActionTypes, nextApp: App) => () => {
      setApp(nextApp);

      if (type === AppCardActionTypes.cabinet) {
        setOpenAppModalState(true);
      }
      if (type === AppCardActionTypes.naming) {
        setIsNamingGeneratorModalOpen(true);
      }
    },
    [setApp, setIsNamingGeneratorModalOpen, setOpenAppModalState]
  );

  const tableOptions = useMemo<TableOption[]>(
    () => [
      { dataKey: 'name', title: '' },
      { dataKey: 'action', title: '', width: '8%' }
    ],
    []
  );

  const tableRows = useMemo<TableRow[]>(
    () =>
      apps.map((app) => {
        return {
          key: app.id,
          name: app.name,
          action: (
            <Button
              variant='outlined'
              onClick={handleAppCardAction(AppCardActionTypes.cabinet, app)}
            >
              {t('apps.cabinet')}
            </Button>
          )
        };
      }),
    [apps, handleAppCardAction, t]
  );

  return (
    <Table
      hideHead
      fullWidth
      isLoading={isLoading}
      options={tableOptions}
      isEmpty={!tableRows.length}
      rows={tableRows}
    />
  );
};

export default AppsTable;
