import React, { useRef } from 'react';
import Page from '../../components/Page';
import Header from '../../components/Header';
import GoBackLink from '../../components/GoBackLink';
import { useTranslation } from 'react-i18next';
import useRoutes from '../../hooks/useRoutes';
import { RouteComponentProps } from 'react-router-dom';
import OffersApi from '../../api/offers';
import { Offer as OfferInterface } from '../../types/offers';
import { mapOffer } from '../../maps/offersMap';
import Grid from '../../components/Grid';
import Block from '../../components/Block';
import Offer from '../../components/Offer';
import styled from 'styled-components';
import ReadOnlyInfo from '../../components/ReadOnlyInfo';
import { color } from '../../bit/components/utils/utils';
import BlockHeader from '../../components/BlockHeader';
import GoalsTable from './GoalsTable';
import LandingsTable from './LandingsTable';
import AdditionalParamsModal from '../../modals/AdditionalParamsModal';
import { ReadOnlyInfoItemType } from '../../components/ReadOnlyInfo/interface';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../redux/slices/userSlice';
import { fetchAppsAsync } from '../../redux/slices/appsSlice';
import RequestPromoteButton from '../../components/RequestPromoteButton';
import media from '../../resources/media';

const StyledHeader = styled(Header)`
  flex-direction: row;

  button {
    margin-top: 0;
    margin-left: auto;
    align-self: flex-start;

    @media ${media.tablet} {
      display: none;
    }
  }
`;

const OfferInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 24px 24px;
  border-top: 1px solid ${color('border.main')};
`;

const MobileRequestPromoteButton = styled(RequestPromoteButton)`
  display: none;
  width: calc(100% - 32px);
  margin: 16px;

  @media ${media.tablet} {
    display: inline-flex;
  }
`;

const OfferPage = ({ match }: RouteComponentProps<{ id: string }>) => {
  const { id } = match.params;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getRoutePath } = useRoutes();
  const { info } = useSelector(selectUser);

  const isDidMount = useRef(false);

  const [offerState, setOfferState] = React.useState<
    OfferInterface | undefined
  >();

  const [
    additionalParamsModalOpen,
    setAdditionalParamsModalOpen
  ] = React.useState(false);

  const closeAdditionalParamsModal = React.useCallback(
    () => setAdditionalParamsModalOpen(false),
    []
  );

  React.useEffect(() => {
    if (!isDidMount.current) {
      const getOffer = async () => {
        const response = await OffersApi.getOffer(id);
        setOfferState(mapOffer(response.data.data));
      };

      getOffer();

      dispatch(fetchAppsAsync());

      isDidMount.current = true;
    }
  }, [dispatch, id, info]);

  const offerInfo = React.useMemo(() => {
    if (!offerState) {
      return [];
    }

    return [
      {
        id: 'description',
        label: t('common.description'),
        value: offerState.description,
        type: ReadOnlyInfoItemType.html
      }
    ];
  }, [offerState, t]);

  return (
    <Page>
      <StyledHeader>
        <GoBackLink to={getRoutePath('offers')} label={t('sidebar.offers')} />
        <RequestPromoteButton visible={!offerState?.canPromote} offerId={id} />
      </StyledHeader>
      {offerState && (
        <Grid>
          <Block disablePadding fullWidth>
            <Offer
              offerId={offerState.id}
              canPromote={offerState.canPromote}
              isOfferPage
              title={offerState.title}
              image={offerState.image}
            />
            <OfferInfo>
              <ReadOnlyInfo info={offerInfo} />
            </OfferInfo>
            <MobileRequestPromoteButton
              visible={!offerState?.canPromote}
              offerId={id}
            />
          </Block>
          <Block fullWidth disablePadding>
            <BlockHeader addPadding title={t('offers.goal', { count: 5 })} />
            <GoalsTable goals={offerState.goals} />
          </Block>
          <Block fullWidth disablePadding>
            <BlockHeader addPadding title={t('offers.landing', { count: 5 })} />
            <LandingsTable landings={offerState.landings} />
          </Block>
          <AdditionalParamsModal
            open={additionalParamsModalOpen}
            onClose={closeAdditionalParamsModal}
          />
        </Grid>
      )}
    </Page>
  );
};

export default OfferPage;
