import React, { useMemo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Picker, { registerLocale } from 'react-datepicker';

import { DatePickerProps } from './DatePickerTypes';

import ru from 'date-fns/locale/ru';
import ua from 'date-fns/locale/uk';
import en from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';

import Input from '../Input';
import calendar from '../../images/calendar.svg';
import Icon from '../../bit/components/icon';
import moment from 'moment';
import { DATE_FORMAT } from '../../resources/constants';

registerLocale('ru', ru);
registerLocale('en', en);
registerLocale('ua', ua);

const PickerWrapper = styled('div')<{ $isError?: boolean }>`
  width: 240px;

  .react-datepicker-wrapper {
    width: 100%;
  }

  svg path {
    fill: transparent;
  }

  .react-datepicker__input-container > div {
    padding: 0;
    border: none;

    ${({ $isError }) =>
      $isError &&
      `
        input {
          border-color: red 
        }
    `}
  }

  & .react-datepicker {
    border-color: ${({ theme }) => theme.palette.border.main};
    background: ${({ theme }) => theme.palette.background.main};

    & .react-datepicker__day,
    & .react-datepicker__day-name,
    & .react-datepicker__current-month {
      color: ${({ theme }) => theme.palette.text.main};
    }

    & .react-datepicker__header {
      border-color: ${({ theme }) => theme.palette.border.main};
      background: ${({ theme }) => theme.palette.background.main};
    }

    & .react-datepicker__day--in-range,
    & .react-datepicker__day--in-selecting-range,
    & .react-datepicker__day--selected {
      color: ${({ theme }) => theme.palette.common.white};
    }

    & .react-datepicker__triangle {
      border-bottom-color: ${({ theme }) => theme.palette.background.main};
      &::before {
        border-bottom-color: ${({ theme }) => theme.palette.border.main};
      }
    }

    &
      .react-datepicker__day:not(.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range, .react-datepicker__day--selected) {
      &:hover {
        background: ${({ theme }) => theme.palette.border.main};
      }
    }
  }
`;

const StyledPicker = styled(Picker)`
  width: 100%;

  display: inline-flex;
  padding: 16px;
  border-radius: 4px;

  outline: none;
  font-size: 14px;
  line-height: 17px;
  resize: vertical;
  transition: background-color 250ms ease-in-out;
`;

const removeDateTimeZone = (data: Date) => {
  const [date] = new Date(data).toISOString().split('T');
  return new Date([date, '00:00:00.000Z'].join('T'));
};

const prepareDate = (date: Date, isOut = false) => {
  return moment(removeDateTimeZone(date))
    .add(isOut ? -1 : 1, 'days')
    .toDate();
};

// TODO: локализация
// TODO: enum

const DatePicker = (props: DatePickerProps) => {
  const {
    onChange,
    showMonthDropdown,
    showYearDropdown,
    selectsRange = true,
    isValueWithoutTimeZone = false
  } = props;

  const { i18n } = useTranslation();

  const [isOpen, setOpenState] = useState(false);
  const [isValid, setValidState] = useState(true);

  const endDate = useMemo(() => {
    if (!props.endDate) return null;
    if (!isValueWithoutTimeZone) return props.endDate;

    return prepareDate(props.endDate);
  }, [props.endDate, isValueWithoutTimeZone]);

  const startDate = useMemo(() => {
    if (!props.startDate) return null;
    if (!isValueWithoutTimeZone) return props.startDate;

    return prepareDate(props.startDate);
  }, [props.startDate, isValueWithoutTimeZone]);

  const toggleOpenState = useCallback(
    (state?: boolean) => {
      setOpenState(state || !isOpen);
    },
    [isOpen]
  );

  const CustomInput = useMemo(
    () => (
      <Input
        readOnly
        endIcon={<Icon src={calendar} onClick={() => toggleOpenState()} />}
      />
    ),
    [toggleOpenState]
  );

  const value = useMemo(() => {
    const start = startDate ? new Date(startDate).toLocaleDateString() : '';
    const end = endDate ? ` - ${new Date(endDate).toLocaleDateString()}` : '';

    return start + end;
  }, [startDate, endDate]);

  const onChangeDate = useCallback(
    (dates: [Date, Date]) => {
      const prevDates = dates;
      if (isValueWithoutTimeZone) {
        // NOTE
        // Дейтпикер некорректные типы имеет
        // По факту Date | [Date, Date]

        if (Array.isArray(dates)) {
          if (dates[0]) {
            dates[0] = prepareDate(dates[0], true);
          }

          if (dates[1]) {
            dates[1] = prepareDate(dates[1], true);
          }
        } else {
          if (dates) {
            // @ts-ignore
            dates = prepareDate(dates, true);

            // @ts-ignore
            const difference = prevDates.getTime() - dates.getTime();
            const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

            if (totalDays === 2) {
              // @ts-ignore
              dates = prepareDate(dates);
            }
          }
        }

        onChange(dates);
        setValidState(true);
        return;
      }

      onChange(dates);
      setValidState(true);
    },
    [onChange, isValueWithoutTimeZone]
  );

  const onChangeRaw = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value?.trim();

    if (!inputValue) return;

    const [startDate, endDate] = inputValue
      .split(' - ')
      .map((date) => moment(date, DATE_FORMAT, true));

    const isStartDateValid = startDate?.isValid();
    const isEndDateValid = endDate?.isValid();

    if (selectsRange) {
      if (isStartDateValid && isEndDateValid) {
        setValidState(true);
        onChange([startDate.toDate(), endDate.toDate()]);
      } else {
        setValidState(false);
      }
    } else {
      if (isStartDateValid) {
        setValidState(true);
        onChangeDate([startDate.toDate(), startDate.toDate()]);
      } else {
        setValidState(false);
      }
    }
  };

  return (
    <PickerWrapper $isError={!isValid}>
      <StyledPicker
        showYearDropdown={showYearDropdown}
        showMonthDropdown={showMonthDropdown}
        customInput={CustomInput}
        open={isOpen}
        onFocus={() => toggleOpenState(true)}
        onClickOutside={() => toggleOpenState(false)}
        shouldCloseOnSelect={false}
        selected={startDate}
        onChange={onChangeDate}
        endDate={endDate}
        startDate={startDate}
        selectsRange={selectsRange}
        locale={i18n.language}
        placeholderText={selectsRange ? 'Выберите даты' : 'Выберите дату'}
        dateFormat='dd.MM.yyyy'
        onChangeRaw={onChangeRaw}
        value={value}
      />
    </PickerWrapper>
  );
};

export default DatePicker;
