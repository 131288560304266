import React from 'react';
import styled from 'styled-components';
import PopperIcon from '../PopperIcon';

import { Props } from './interface';
import HeaderTitle from '../HeaderTitle';
import { color } from '../../bit/components/utils/utils';
import SidebarButton from '../SidebarButton';
import media from '../../resources/media';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 26px 24px;

  background-color: ${color('background.main')};

  & > * + * {
    margin-top: 20px;
  }
`;

const TitleContainerWithButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 52px;

  @media ${media.tablet} {
    & > div + div {
      margin-left: 20px;
    }
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  width: 100%;

  @media ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;

    & > span {
      margin: 0px;
      & + span {
        margin-top: 20px;
      }
    }
  }
`;

const StyledSidebarButton = styled(SidebarButton)`
  margin-left: auto;
`;

const Header = ({
  title,
  additionalTitle,
  children,
  className,
  popperProps
}: Props) => {
  return (
    <StyledHeader className={className}>
      {title && (
        <TitleContainerWithButton>
          <TitleContainer>
            <HeaderTitle>{title}</HeaderTitle>
            {popperProps && (
              <PopperIcon {...popperProps}>{popperProps.title}</PopperIcon>
            )}
            {additionalTitle}
          </TitleContainer>
          <StyledSidebarButton />
        </TitleContainerWithButton>
      )}
      {children}
    </StyledHeader>
  );
};

export default Header;
