import Api from '../../api';
import OffersApi from '../../api/offers';
import { Props, StyledOfferProps } from './interface';
import styled, { css } from 'styled-components';
import plug from '../../images/plug.jpg';
import React, { useCallback, useMemo } from 'react';
import Typography from '../../bit/components/typography';
import { useTranslation } from 'react-i18next';
import { color } from '../../bit/components/utils/utils';
import { Link } from 'react-router-dom';
import useRoutes from '../../hooks/useRoutes';
import Notification from '../Notification';
import transientProps from '../../utils/transientProps';

const OfferImage = styled.div`
  flex: none;
  width: 50px;
  height: 50px;
  margin-right: 8px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const OfferInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const OfferStatus: any = styled(Typography)<{
  isOfferPage?: boolean;
  canPromote?: boolean;
}>`
  position: relative;
  margin-top: 8px;
  padding-left: ${(props) => (props.isOfferPage ? 0 : 15)}px;
  color: ${(props) => {
    if (props.isOfferPage) {
      return props.canPromote
        ? color('success.main')(props)
        : color('warning.main')(props);
    }

    return color('text.second')(props);
  }};
  cursor: ${(props) => !props.canPromote && 'pointer'};

  &:before {
    position: absolute;
    left: 0;
    top: 5px;
    flex: none;
    content: '';
    display: ${(props) => (props.isOfferPage ? 'none' : 'inline-flex')};
    width: 7px;
    height: 7px;
    background-color: ${(props) =>
      props.canPromote
        ? color('success.main')(props)
        : color('warning.main')(props)};
    border-radius: 50%;
  }
`;

const OfferTitle: typeof Typography = styled(Typography)<{
  $isLink: boolean;
}>`
  text-decoration: none;

  ${({ $isLink }) =>
    $isLink &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `}
`;

const StyledOffer = styled.div<StyledOfferProps>`
  display: flex;
  align-items: center;

  ${(props) => {
    if (props.isOfferPage) {
      return css`
        padding: 24px 24px 20px;

        ${OfferImage} {
          width: 100px;
          height: 100px;
          margin-right: 16px;
        }
      `;
    }
  }}
`;

const Offer = (props: Props) => {
  const {
    image,
    title,
    offerId,
    isOfferPage,
    withoutStatus,
    canPromote
  } = props;

  const { t } = useTranslation();

  const { getRoutePath } = useRoutes();

  const statusText = useMemo(() => {
    if (canPromote) {
      return isOfferPage ? t('offers.available') : t('offers.availableShort');
    } else {
      return isOfferPage
        ? t('offers.notAvailable')
        : t('offers.notAvailableShort');
    }
  }, [canPromote, isOfferPage, t]);

  const requestPromote = useCallback(async () => {
    if (!offerId) return;

    try {
      await OffersApi.gettingOfferAccess(offerId);

      Notification.show({
        type: 'success',
        message: t('offers.requestPromoteSuccess')
      });
    } catch (e) {
      Api.handleDefaultError(e);
    }
  }, [t, offerId]);

  const isLink = Boolean(!isOfferPage && offerId && !isNaN(offerId as number));

  return (
    <StyledOffer isOfferPage={isOfferPage}>
      {image && (
        <OfferImage>
          <img src={image || plug} alt='offer' />
        </OfferImage>
      )}
      <OfferInfo>
        <OfferTitle
          component={!isLink ? 'span' : Link}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          to={!isLink ? undefined : getRoutePath('offer', offerId)}
          variant='subtitle2'
          size={isOfferPage ? 18 : 14}
          color='main'
        >
          {title}
        </OfferTitle>

        {!withoutStatus && (
          <OfferStatus
            onClick={!canPromote ? requestPromote : undefined}
            isOfferPage={isOfferPage}
            canPromote={canPromote}
            variant='subtitle2'
            size={isOfferPage ? 16 : 14}
            color='inherit'
          >
            {statusText}
          </OfferStatus>
        )}
      </OfferInfo>
    </StyledOffer>
  );
};

export default Offer;
