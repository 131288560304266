import Icon from '../../bit/components/icon';
import React, { useState } from 'react';

import infoIcon from '../../images/info.svg';
import Popper from '../Popper';
import { Props } from './interface';
import { IconButton, styled } from '@mui/material';

const Overlay = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

const PopperIcon = ({ children, isShow, toggleShowState }: Props) => {
  const [
    referenceElement,
    setReferenceElement
  ] = useState<HTMLDivElement | null>(null);

  const openPopper = () => toggleShowState?.(true);
  const closePopper = () => toggleShowState?.(false);

  return (
    <>
      {isShow && <Overlay onClick={closePopper} />}

      <StyledIconButton
        disableTouchRipple
        onMouseEnter={openPopper}
        onMouseLeave={closePopper}
      >
        <Icon src={infoIcon} ref={setReferenceElement} />
      </StyledIconButton>

      <Popper
        referenceElement={referenceElement}
        isShow={isShow}
        maxWidth={430}
      >
        {children}
      </Popper>
    </>
  );
};

export default PopperIcon;
