import styled from 'styled-components';
import Dropdown from '../../components/Dropdown';
import Header from '../../components/Header';
import Tabs, { Tab } from '../../bit/components/tabs';
import media from '../../resources/media';
import Grid from '../../components/Grid';

export const AdditionalHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledDropdown = styled(Dropdown)`
  min-width: 180px;
`;

export const HeaderContainer = styled(Header)`
  padding-bottom: 0 !important;

  > div {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const HeaderBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderFiltration = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderFiltrationRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -12px -12px 12px;

  > * {
    width: 240px;
    margin: 12px;

    @media ${media.tablet} {
      width: 100%;
    }
  }
`;

export const HeaderTabs = styled.div`
  height: 48px;
  max-width: 317px;
  width: 100%;

  box-sizing: border-box;

  background: ${({ theme }) => theme.palette.background.main};

  & > div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledTabContainer = styled(Tabs)`
  height: 100%;
  border: none;
  border-radius: 0;
`;

export const StyledTab = styled(Tab)<{ $isAutoWidth?: boolean }>`
  ${({ $isAutoWidth }) => $isAutoWidth && `flex: initial`}
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  padding: 12px;
  padding-top: 0;
  margin-top: -12px;
`;

export const ReportsContent = styled(Grid)`
  padding: 20px;
`;
